<template>
  <default-layout>
    <nav class="education__nav">
      <ul>
        <li @click="changeUrl('network')">
          {{ $t("alumni.nav.network") }}
        </li>
      </ul>
      <select name="course" v-model="searchCourse" id="select-course">
        <option v-for="i in range(32, 42)" :value="`k${i}`" :key="i">
          Promotion {{ i }}
        </option>
        <option value="k">Show All</option>
      </select>
    </nav>

    <div style="padding: 50px; background-color: #f4f7fa">
      <div class="research__table__container custom-scrollbar">
        <div v-html="currentHtmlContent" id="myTable"></div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";

export default {
  data() {
    return {
      currentHtmlContent: "",
      searchCourse: "k",
    };
  },
  watch: {
    searchCourse() {
      this.filterFunc(this.searchCourse);
    },
  },
  methods: {
    toResearch(path) {
      this.$router.push(path);
    },
    async changeUrl(course = "") {
      this.$swal({
        title: "Loading",
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          this.$swal.showLoading();
        },
      });
      try {
        const response = await this.$axios.get(`/api/jsxlsx/alumni/${course}`);
        this.filterFunc("k");
        this.currentHtmlContent = response.data;
        this.$swal.close();
      } catch (err) {
        this.$swal({
          icon: "error",
        });
      }
    },
    range(min, max) {
      return this.$helpers.range(min, max);
    },
    filterFunc(input = "") {
      var filter, table, tr, td, i, txtValue;
      filter = input.toUpperCase();
      table = document.getElementById("myTable");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[3];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
  },
  async created() {
    this.changeUrl("network");
  },
  components: {
    DefaultLayout,
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/alumni.scss";
</style>